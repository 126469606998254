import React from 'react';
import {
    Route,
    Redirect,
} from "react-router-dom";

export default function PrivateRoute({redirectPath= "/", condition = true, children, ...rest}) {
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                condition ? (
                    React.cloneElement(
                        children,
                        {...routeProps}
                    )
                ) : (
                    <Redirect push
                        to={{
                            pathname: redirectPath,
                        }}
                    />
                )
            }
        />
    );
}