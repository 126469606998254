import React, {useEffect, useState} from "react";
import "./ArticleCategoryList.css";
import API from "../../utils/ApiParams";
import {Link} from "react-router-dom";
import Search from "../../images/searchBlue.svg";
import {DebounceInput} from 'react-debounce-input';
import slugify from 'slugify';
import BannerRestauration from "../../images/articlesList/Restauration.jpg"
import BannerAchat from "../../images/articlesList/Achats.jpg"
import BannerCommercial from "../../images/articlesList/Commercial.jpg"
import BannerGeneral from "../../images/articlesList/General.jpg"
import BannerLogistique from "../../images/articlesList/Logistique.jpg"
import BannerAll from "../../images/articlesList/Tous les articles.jpg"
import CustomSelect from "../CustomSelect";
import Loading from "../Loading";
export default function ArticleCategoryList(props) {
    const [category, setCategory] = useState(getCategory())
    const [searchCategory, setSearchCategory] = useState({});
    const [subCategories, setSubCategories] = useState([]);
    const [searchSubCategory, setSearchSubCategory] = useState({});
    const [searchDate, setSearchDate] = useState({name:"Date descendante", value:"desc"});
    const [date] =  useState([{name:"Date ascendante", value:"asc"}, {name:"Date descendante", value:"desc"}])

    const [search, setSearch] = useState("");
    const [articles, setArticles] = useState([]);
    const [articlesLoaded, setArticlesLoaded] = useState(false);
    const [page,setPage] = useState(1);
    const [showMore,setShowMore] = useState(false);
    const [loadingMore,setLoadingMore] = useState(false);
    const [itemsToLoad] = useState(getItemsToLoad());

    useEffect(()=>{
        if(props.location.state.category !== category){
            setCategory(props.location.state.category);
            setArticles([])
            setArticlesLoaded(false);
            setShowMore(false);
            setPage(1);
            setSearchSubCategory({});
        }
    }, [props.location])
    useEffect(()=>{
        async function getArticles(){
            let url = `articles?isArchived=false&status.index=2&order%5BcreationDate%5D=${searchDate.value}&page=1&itemsPerPage=${itemsToLoad}`;
            if(category){
                url += `&categories.id=${category.id}`;
            }
            const response = await API.get(url);
            return response.data;
        }
        async function getSubcategories() {
            let url = "sub_categories?status.index=2";
            if(category){
                url += `&category.id=${category.id}`;
            }
            const response = await API.get(url);
            return response.data;
        }

        getArticles().then(data=>{
            if(data.length<itemsToLoad){
                setShowMore(false);
            } else{
                setShowMore(true)
            }
            setArticlesLoaded(true);
        });
        getSubcategories().then(data=>setSubCategories(data));
    }, [category])
    async function getArticles(nbPage) {
        let url = `articles?isArchived=false&status.index=2&order%5BcreationDate%5D=${searchDate.value}&order%5Bcategories.position%5D=asc&page=${nbPage}&itemsPerPage=${itemsToLoad}`;
        if (category) {
            url += `&categories.id=${category.id}`;
        } else if (Object.keys(searchCategory).length > 0) {
            url += `&categories.id=${searchCategory.id}`;
        }
        if (Object.keys(searchSubCategory).length > 0) {
            url += `&subCategories.id=${searchSubCategory.id}`;
        }
        if (search.length>0){
            url += `&title=${search}`;
        }
        const response = await API.get(url);
        return response.data;
    }
    useEffect(()=>{
        setPage(1);
        getArticles(1).then(data=>{
            if(data.length<itemsToLoad){
                setShowMore(false);
            } else{
                setShowMore(true)
            }
            setArticles(data)
        });
    }, [search, searchCategory, searchSubCategory, searchDate])

    function loadMore() {
        setLoadingMore(true);
        getArticles(page+1).then(data=>{
            const tempData = [...articles];
            if(data.length<itemsToLoad){
                setShowMore(false);
            }
            data.forEach(art=>{
                tempData.push(art);
            })
            setArticles(tempData);
            setLoadingMore(false);
        });
        setPage(page+1);
    }
    function getCategory() {
        if(props.location){
            if(props.location.state.category){
                return props.location.state.category;
            }
        }
        return null;
    }
    function getItemsToLoad() {
        const width = window.innerWidth;
        if(width>1200){
            return 12;
        } else if (width>678 && width<=1200){
            return 10;
        }else if (width<=678){
            return 5;
        }
    }
    function handleChangeSearchCategory(category) {
        if(Object.keys(category).length>0){
            setSearchCategory(props.categories.find(c => c.id === category.id))
        } else {
            setSearchCategory({});
        }
        setSearchSubCategory({});
    }
    function handleChangeSearchSubCategory(subCategory) {
        if(Object.keys(subCategory).length>0){
            setSearchSubCategory(subCategories.find(sc => sc.id === subCategory.id))
        } else {
            setSearchSubCategory({});
        }
    }
    //Rendering functions
    function getBanner() {
        if(category)
            switch (category.id) {
                case 1: return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerGeneral} alt="Banner"/>
                        <div className={"articleBannerFilter category-1-background"}/>
                    </div>
                )
                case 2: return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerAchat} alt="Banner"/>
                        <div className={"articleBannerFilter category-2-background"}/>
                    </div>
                )
                case 3: return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerLogistique} alt="Banner"/>
                        <div className={"articleBannerFilter category-3-background"}/>
                    </div>
                )
                case 4: return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerCommercial} alt="Banner"/>
                        <div className={"articleBannerFilter category-4-background"}/>
                    </div>
                )
                case 5: return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerRestauration} alt="Banner"/>
                        <div className={"articleBannerFilter category-5-background"}/>
                    </div>
                )
                default : return(
                    <div>
                        <img className={"articleListBannerImage"} src={BannerAll} alt="Banner"/>
                        <div className={"articleBannerFilter articleBannerDefaultFilter"}/>
                    </div>
                )
            }
        return (
            <div>
                <img className={"articleListBannerImage"} src={BannerAll} alt="Banner"/>
                <div className={"articleBannerFilter articleBannerDefaultFilter"}/>
            </div>        )
    }
    return(
        <div className={"articleList"}>
            <div className={"articleListBanner"}>
                {getBanner()}
                <div className={"articleListTitle"}>
                    {category?category.name:"Tous les articles"}
                </div>
            </div>
            <div className={"articleListWrapper"}>
                <div className={"articleListFilterBar"}>
                    <div className={"articleListGroupSearch"}>
                        <DebounceInput className={`articleListSearchInput ${props.location.state.category?`category-${props.location.state.category.id}-borderBottom`:""}`}
                                       type="text" value={search} placeholder={"Recherche"}
                                       onChange={(e)=>setSearch(e.target.value)} debounceTimeout={500}/>
                        <img className={"articleListPicto"} src={Search} alt="Loupe"/>
                    </div>
                    <div className={"articleSelects"}>
                        {!category &&
                        <CustomSelect items={props.categories} selectedItem={searchCategory} displayField={"name"} defaultValue={"Toutes les catégories"}
                            handleChange={handleChangeSearchCategory} inputClass={"articleCustomSelect"}
                            activeInputClass={"activeArticleCustomSelect"} afterClass={"articleCustomSelectAfter"}
                            listClass={`articleCustomSelectList`} itemClass={"articleCustomSelectCategory"} inputRowClass={"articleCustomSelectRow"}/>
                        }
                        <CustomSelect items={subCategories.map(sc=>{
                            if(category || searchCategory.id === sc.category.id)
                                return sc;
                            else return null;
                        })} selectedItem={searchSubCategory} displayField={"name"} defaultValue={"Toutes les sous-catégories"}
                                      handleChange={handleChangeSearchSubCategory}
                                      inputClass={`articleCustomSelect ${props.location.state.category?`category-${props.location.state.category.id}-select`:""}`}
                                      activeInputClass={`activeArticleCustomSelect`} afterClass={"articleCustomSelectAfter"}
                                      listClass={`articleCustomSelectList ${props.location.state.category?`category-${props.location.state.category.id}-select`:""}`}
                                      itemClass={`articleCustomSelectCategory ${props.location.state.category?`category-${props.location.state.category.id}-selectHover`:""}`}
                                      inputRowClass={"articleCustomSelectRow"}/>

                        <CustomSelect items={date} selectedItem={searchDate} displayField={"name"}
                                      handleChange={(item)=>setSearchDate(item)}
                                      inputClass={`articleCustomSelect ${props.location.state.category?`category-${props.location.state.category.id}-select`:""}`}
                                      activeInputClass={"activeArticleCustomSelect"} afterClass={"articleCustomSelectAfter"}
                                      listClass={`articleCustomSelectList ${props.location.state.category?`category-${props.location.state.category.id}-select`:""}`}
                                      itemClass={`articleCustomSelectCategory ${props.location.state.category?`category-${props.location.state.category.id}-selectHover`:""}`}
                                      inputRowClass={"articleCustomSelectRow"}/>
                    </div>
                </div>
                <div className={"articles"}>
                    {articlesLoaded ?
                        articles.map(art=>{
                        return(
                            <Link to={`/article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`} key={art.id} className={"articleWrapper"}>
                                <div className={"articleBanner"}>
                                    <img className={"articleBannerImg"}
                                         src={`${process.env.REACT_APP_API_URL}images/${art.thumbnail ? art.thumbnail.id : art.coverImage.id}/${art.thumbnail ? art.thumbnail.name : art.coverImage.name}`} alt=""/>
                                    <div className={`articleBannerCategories`}>
                                        {category &&
                                            <div className={`articleBannerCategory category-${category.id}-full`}>
                                                {category.name}
                                            </div>
                                        }
                                        {art.categories.map(cat=>{
                                           if (!category || category.id !== cat.id){
                                            return(
                                                <div key={cat.id} className={`articleBannerCategory ${cat?`category-${cat.id}-full`:""}`}>
                                                {cat.name}
                                                </div>
                                            )
                                           } else return null;
                                        })}
                                    </div>
                                </div>
                                <div className={"articleContent"}>
                                    <div className={"articleOverview"}>
                                        <div className={`articleTitle ${props.location.state.category?`category-${props.location.state.category.id}-color`:""}`}>
                                            {art.title}
                                        </div>
                                        <div className={"articleDate"}>
                                            Publié le {new Date(art.creationDate).toLocaleDateString('fr-FR',{ year: 'numeric', month: 'long', day: 'numeric' })}
                                        </div>
                                        <div className={"articleText"} dangerouslySetInnerHTML={{__html:art.shortText}}/>
                                    </div>
                                    <button
                                        className={`articleButton ${category 
                                            ?`category-${category.id}-button`
                                            :`category-${art.categories[0].id}-button`}`}>
                                        Lire la suite
                                    </button>
                                </div>
                            </Link>
                        )
                    })
                    : <Loading/>
                    }
                    {articles.length % 3 === 2 &&
                        <div className={"empty"}/>
                    }
                </div>
                {loadingMore &&
                    <Loading/>
                }
                {showMore &&
                <div className={"articlesShowMore"}>
                    <button type={"button"} onClick={() => loadMore()}
                            className={`articlesShowMoreButton ${category
                            && `category-${category.id}-background category-${category.id}-border category-${category.id}-hover`}`}>
                        Afficher plus d'articles
                    </button>
                </div>
                }
            </div>

        </div>
    )
}