import React from "react";
import "./LegalNotices.css";

export default function LegalNotices(props) {
    console.log(props);

    return (
        <div className={"LegalNotices"}>
            <div className={"headerBackground"}></div>
            <div className={"banner"}>
                <div className={"title"}>
                    Mentions légales
                </div>
            </div>
            <div className={"column"}>
                <div className="row">
                    <div className="title">
                        L'éditeur
                    </div>
                    DISTRIBOISSONS est le propriétaire et l’éditeur exclusif du
                    site <a href="newsletter.distriboissons.com">newsletter.distriboissons.com</a>. <br/>
                    Distriboissons est une Société par Actions Simplifiées au capital variable minimum de 100 000€,
                    inscrite au registre du commerce et des sociétés de Paris sous l’immatriculation 349 309 096 et sous
                    le numéro
                    de TVA intracommunautaire suivant : FR 22 349 309 096.
                </div>
                <div className="row">
                    <div className="title">
                        L’hébergeur
                    </div>
                    Ce site est hébergé par OVH, SAS au capital de 10 069 020 €. <br/>
                    Siège social : 2, rue Kellermann 59100 Roubaix <br/>
                    N° d’immatriculation : RCS Lille 537 407 926
                </div>
                <div className="row">
                    <div className="title">
                        Nous contacter
                    </div>
                    Nos équipes sont à votre disposition pour répondre à vos questions sur les canaux suivants : <br /><br />
                    Par email : contact@distriboissons.com <br />
                    Par téléphone : 01 40 06 06 21<br/>
                </div>
                <div className="row">
                    <div className="title">
                        Conditions d’accès au site
                    </div>
                    Le site <a href="newsletter.distriboissons.com">newsletter.distriboissons.com</a> a pour raison d’exister
                    d’informer les internautes
                    sur les activités du groupement et de ses partenaires. <br/>
                    La vente d’alcool faisant partie intégrante de notre activité, l’accès au site est strictement
                    réservé aux personnes majeures. <br/>
                </div>
                <div className="row">
                    <div className="title">
                        Cookies
                    </div>
                    Les cookies sont utilisés par la grande majorité des sites internet afin d’optimiser la navigation
                    des internautes.
                    Un cookie est un fichier simple, enregistré temporairement sur le disque dur de l’internaute, à la
                    demande
                    du serveur gérant le site web visité. Il contient des informations sur la navigation
                    effectuée sur le site en question. <br/>
                    Ce site utilise deux sortes de cookies :
                    <ul>
                        <li>
                            Le cookie de Google Analytics pour le suivi du trafic sur notre site (nombre de visites
                            etc.)
                        </li>
                        <li>Les cookies sont gérés par le navigateur de l’internaute (Google Chrome, Mozilla Firefox
                            etc.),
                            il est donc possible de les désactiver dans les paramètres de ce dernier, cependant la
                            fonctionnalité de certaines pages en sera réduite.
                        </li>
                    </ul>
                </div>
                <div className="row">
                    <div className="title">
                        Propriété intellectuelle
                    </div>
                    Le site <a href="newsletter.distriboissons.com">newsletter.distriboissons.com</a> bénéficie de la loi en vigueur
                    concernant la protection des droits de propriété intellectuelle. Ainsi l’intégralité du contenu
                    du site (textes, images, illustrations, graphiques, logos, marques et autres documents) est
                    l’exclusive propriété de Distriboissons et ne peut en aucun cas être reproduit
                    et/ou diffusé sans son autorisation préalable. <br/> <br/>
                    La mise en place de liens hypertextes en direction de ce site est également strictement soumise
                    à l’autorisation de Distriboissons. <br/> <br/>
                    Le non respect des deux points cités plus haut exposerait son auteur à des poursuites juridiques.
                </div>
            </div>


            <div className={"banner"}>
                <div className={"title"}>
                    Règles relatives à l'application mobile Distrinews
                </div>
            </div>
            <div className={"column"}>
                <div className="row">
                    <div className="title">
                        Accès à l’Application
                    </div>
                    L’accès à l’Application est strictement réservé aux Utilisateurs, étant précisé que cet accès est
                    strictement personnel et fait l’objet d’un mot de passe personnalisé pour chacun d’entre
                    eux.<br/><br/>Les Utilisateurs doivent posséder un terminal mobile compatible doté d’un accès réseau
                    internet et télécharger l’Application.<br/><br/>L’Application est téléchargeable gratuitement depuis
                    Apple Store ou Google Play.<br/><br/>La version du logiciel de l’Application peut être mise à jour
                    de temps à autre pour ajouter de nouvelles fonctions et/ou de nouveaux services.
                </div>
                <div className="row">
                    <div className="title">
                        Conditions financières de l’Application
                    </div>
                    L’Application est proposée gratuitement aux Utilisateurs hors coûts d’abonnement auprès de
                    l’opérateur de téléphonie mobile, hors coûts de connexion et d’accès au réseau Internet et hors
                    surcoût éventuel facturé pour le chargement des données ou l’envoi de SMS.
                </div>
                <div className="row">
                    <div className="title">
                        Application des Conditions Générales d’Utilisation et des Conditions Générales de Vente
                    </div>
                    Les CGU sont applicables de plein droit à tout Utilisateur.<br/><br/>L’Utilisateur est invité à lire
                    attentivement les
                    CGU qui sont susceptibles d’être modifiées à tout moment et sans préavis par
                    l’Editeur.<br/><br/>L’Application pourra faire l’objet d’une mise à jour régulière. A cet égard,
                    toute Application téléchargée par un Utilisateur a une validité limitée dans le temps, compte tenu
                    de ces mises à jour à télécharger impérativement et des éventuelles évolutions techniques,
                    législatives ou réglementaires, dont l’Editeur n’a pas le contrôle.
                </div>
                <div className="row">
                    <div className="title">
                        Propriété intellectuelle
                    </div>
                    L’intégralité des données et informations contenues sur l’Application sont protégées par la loi en
                    vigueur concernant la protection des droits d’auteur et des droits de propriété intellectuelle.
                    Ainsi l’intégralité du contenu de l’Application (textes, images, illustrations, graphiques, logos,
                    marques, et tout autre document) sont la propriété exclusive de l’Editeur et ne peuvent en aucun cas
                    être reproduits, copiés et/ou diffusés sans son autorisation préalable.<br/><br/>Le non-respect de
                    cette interdiction est susceptible de constituer un acte de contrefaçon sanctionné par les articles
                    L. 335-2 et suivants du Code de la propriété intellectuelle.<br/><br/>Le fait pour l’Editeur de ne
                    pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas
                    acceptation desdites utilisations et renonciation aux poursuites.<br/><br/>Il est strictement
                    interdit à l’Utilisateur d’accéder et/ou d’utiliser les codes source de l’Application et/ou des
                    composants logiciels de l’Application.<br/><br/>L’Utilisateur n’acquiert aucun droit de propriété
                    intellectuelle sur l’Application, le contenu et/ou les services ni aucun autre droit que ceux
                    conférés par les présentes.
                </div>
                <div className="row">
                    <div className="title">
                        Licence d’utilisation
                    </div>
                    L’Editeur concède gratuitement à l’Utilisateur un droit personnel d’utilisation de l’Application, de
                    son contenu et des services mis à sa disposition non exclusif, révocable, non cessible et non
                    transférable uniquement pour ses besoins propres lors de l’utilisation de l’Application conformément
                    à son objet décrit ci-dessus, à l’exclusion de toute autre finalité.
                </div>
                <div className="row">
                    <div className="title">
                        Gestion de l’Application/Responsabilités :
                    </div>
                    Pour la bonne gestion de l’Application, l’Editeur pourra à tout moment :
                    <ul>

                        <li>suspendre, interrompre ou limiter l’accès à tout ou partie de l’Application, réserver l’accès à l’Application ou à certaines parties de l’Application, à tout ou partie des Utilisateurs ;</li>
                        <li> supprimer toute information et/ou tout contenu et/ou toute fonctionnalité pouvant en perturber le fonctionnement ou étant contraires aux lois et règlements nationaux ou internationaux ;</li>
                        <li> suspendre l’Application afin de procéder à des mises à jour.</li>
                    </ul>

                    La responsabilité de l’Editeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement empêchant l’accès à l’Application ou à une de ses fonctionnalités. L’Editeur se réserve le droit de modifier à tout moment les informations notamment en actualisant l’Application.<br /><br />
                    L’Editeur ne pourra être tenu responsable en cas de poursuites judiciaires à l’encontre de l’Utilisateur :
                    <ul>
                        <li>du fait de l’usage de l’Application ou de tout service accessible via l’Application ;</li>
                        <li>du fait du non-respect des présentes CGU</li>
                    </ul>
                    L’Editeur n’est pas responsable des dommages causés à l’Utilisateur, aux fournisseurs référencés par Distriboissons, aux adhérents et affiliés de Distriboissons et/ou à des tiers et/ou ceux causés l’équipement de l’Utilisateur du fait de l’utilisation de l’Application. A cet égard, l’Utilisateur renonce irrévocablement et par avance à toute action contre l’Editeur de ce fait.<br /><br />L’Editeur n’est pas responsable de l’utilisation qui est faite des informations communiquées sur l’Application.<br /><br />Protection des données personnelles :<br /><br />En tant que de besoin, il est précisé que toute donnée personnelle susceptible d’être collectée dans le cadre de l’utilisation de l’Application sera traitée et conservée conformément à la loi n°78-017 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés modifiée.<br /><br />En cas de communication de données personnelles, l’Utilisateur dispose d’un droit d’accès direct, de rectification et d’opposition des données qui le concernent et qu’il peut exercer sur simple demande à l’adresse suivante : Distriboissons – 5, rue Logelbach – 75017 Paris.
                </div>
                <div className="row">
                    <div className="title">
                        Attribution de juridiction
                    </div>
                    Les présentes CGU sont régies par la loi française et soumises à la compétence des tribunaux du ressort de la Cour d’appel de Paris.
                </div>
            </div>
        </div>
    )
}