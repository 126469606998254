import React from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import GetToken from "../utils/GetToken";
import jwt_decode from "jwt-decode";

const RouteChangeTracker = (props) => {

    const token = GetToken();
    const history = props.history;

    if(token){
        const decodedToken = jwt_decode(token);
        history.listen((location, action) => {
            if(decodedToken.articleId && location.pathname.substr(0,9)!='/article/'){
                props.disconnect();
            }
        });

    }

    // history.listen((location, action) => {
    //
        ReactGA.set({ page: history.location.pathname });
        ReactGA.pageview(history.location.pathname);
    // });

    return <div></div>;
};

export default withRouter(RouteChangeTracker);