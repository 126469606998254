import React, {useContext, useEffect, useRef, useState} from 'react';
import API from "../../utils/ApiParams";
import "./Article.css";
import ReturnPicto from "../../images/content/back.svg"
import PrintPicto from "../../images/content/print.svg"
import SharePicto from "../../images/content/share.svg"
import DownloadPicto from "../../images/content/Download.svg"
import DownloadPictoWhite from "../../images/content/DownloadWhite.svg"
import {Link} from "react-router-dom";
import slugify from "slugify";
import ReactToPrint from "react-to-print";
import UserContext from "../../contexts/UserContext";
import MailGenerator from "../../utils/MailGenerator";
import ReactDOMServer from 'react-dom/server';
import Loading from "../Loading";
import Preview from "../modals/Preview";
import GetToken from "../../utils/GetToken";
import jwt_decode from "jwt-decode";

export default function Article(props) {
    const [article, setArticle] = useState({});
    const [articleLoaded, setArticleLoaded] = useState(false);
    const [lastArticles, setLastArticles] = useState([]);
    const [lastArticlesLoaded, setLastArticlesLoaded] = useState(false);
    const [idArticle, setIdArticle] = useState(null);
    const [questionsAnswers, setQuestionsAnswers] = useState([]);

    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([]);
    const [pageComment,setPageComment] = useState(1);
    const [mail, setMail] = useState("");
    const [showMailModal, setShowMailModal] = useState(false);
    const [newMail,setNewMail] = useState("");
    const [mailSent, setMailSent] = useState(false);
    const [invitationSent, setInvitationSent] = useState(false);
    const [mailError, setMailError] = useState(false);

    const user = useContext(UserContext);
    const componentRef = useRef(null);
    const shareButtonRef = useRef(null);
    const shareModalRef = useRef(null);
    function useOutsideAlerter(ref, secondRef) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)
                    && secondRef.current && !secondRef.current.contains(event.target)) {
                        setShowMailModal(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(shareButtonRef, shareModalRef);

    useEffect(()=>{
        async function getArticle(id) {
            const response = await API.get(`articles/${id}`);
            return response.data;
        }
        async function getLastArticles(){
            const response = await API.get("articles?isArchived=false&status.index=2&order%5BcreationDate%5D=desc&order%5Bcategories.position%5D=asc&itemsPerPage=4");
            return response.data;
        }
        setArticleLoaded(false);
        const splittedSlug = props.match.params.slug.split('-');
        setIdArticle(parseInt(splittedSlug[splittedSlug.length-1]));

        getArticle(splittedSlug[splittedSlug.length-1]).then(data=>{

            const token = GetToken();

            if(token){
                const decodedToken = jwt_decode(token);
                if(decodedToken.articleId && decodedToken.articleId!=data.id){
                    props.disconnect();
                }
            }

            data.categories.sort((a,b)=>{
                return a.position - b.position;
            });
            setArticle(data);
            setArticleLoaded(true);
            getLastArticles().then(newLastArticles=>{
                let tempData = [];
                newLastArticles.forEach(art=>{
                    if (art.id !== data.id && tempData.length < 3){
                        tempData.push(art);
                    }
                })
                setLastArticles(tempData);
                setLastArticlesLoaded(true);
            });
        })

    }, [props.match.params.slug])
    useEffect(()=>{
        let tempQ = [...questionsAnswers]
        if(article.questions){
            article.questions.forEach(question=>{
                question.questionsAnswers.forEach(qa=>{
                    if(qa.person.id === user.id){
                        tempQ.push({id:question.id, answer: qa.answer, canModify:false})
                    }
                })
            })
        }
        setQuestionsAnswers(tempQ);

        if(article.comments && article.comments.length > 0){
            let tempComments = [...article.comments];
            tempComments.sort((a,b)=>{
                return (new Date(b.date) - new Date(a.date));
            })
            setComments(tempComments);
        } else{
            setComments([]);
        }
        setPageComment(1);
        if(Object.keys(article).length > 0)
            document.getElementById("article").scrollIntoView({behavior: "smooth",block: "start", inline: "center"});

    }, [article])
    function handleChangeQuestion(e, id) {
        //console.log(e.target.value + " "+ id)
        let tempQ = [...questionsAnswers]
        if(tempQ.length>0 && tempQ.some(q=> q.id === id)){
            tempQ.map(q=>{
                if(q.id === id){
                    q.answer = e.target.value;
                }
                return q;
            })
        } else{
            tempQ.push({id:id, answer: e.target.value, canModify: true})
        }
        setQuestionsAnswers(tempQ);
    }
    function handleSubmitQuestion(e, id){
        e.preventDefault();
        async function sendAnswer(answer) {
            const response = await API.post("/questions_answers",{
                answer: answer,
                question: `/questions/${id}`,
                person: `/users/${user.id}`
            });
            return response;
        }
        let answer = questionsAnswers.find(q=> q.id === id);
        if(answer){
            sendAnswer(answer.answer).then(response=>{
                if(response.status === 201){
                    let tempQ = [...questionsAnswers];
                    tempQ.map(q=>{
                        if(q.id === id){
                            q.canModify = false;
                        }
                        return q;
                    })
                    setQuestionsAnswers(tempQ)
                }
            })
        }
    }
    /*function handleSubmitComment(e) {
        e.preventDefault();
        async function postComment() {
            const response = await API.post("comments",{
                comment: newComment,
                person: `/users/${user.id}`,
                article: `/articles/${idArticle}`,
                date: new Date(Date.now())
            })
            return response;
        }
        postComment().then(response=>{
            if(response.status === 201){
                setNewComment("");
                let tempComments = [...comments];
                tempComments.unshift(response.data);
                setComments(tempComments);
            }
        })
    }*/
    function handleSubmitMail(e){
        e.preventDefault();

        if(newMail.length>0){
            async function sendMail() {
                const response = await API.post("mail/comment", {
                    title: article.title,
                    id: user.id,
                    content: newMail,
                    author:article.author.mail
                });
                return response;
            }
            sendMail().then(response => {
                if (response.status === 200) {
                    setNewMail("");
                    setMailSent(true);
                } else {
                    setMailError(true);
                }
            }).catch(err => {
                setMailError(true);
            })
        }
    }
    function sendShareLink(){
        async function getToken() {
            const response = await API.get(`login/guest/${article.id}`);
            return response.data;
        }
        async function sendMail(article){
            const response = await API.post("mail/invitation",{
                mail: mail,
                article: article,
                id: user.id,
            });
            return response;
        }
        getToken().then(data=>{
            if (mail && data) {
                const link = window.location.href + "/" + data.token;
                sendMail({
                    title: article.title,
                    categories: article.categories,
                    coverUrl: `${process.env.REACT_APP_API_URL}images/${article.thumbnail ? article.thumbnail.id : article.coverImage.id}/${article.thumbnail ? article.thumbnail.name : article.coverImage.name}`,
                    text: article.shortText,
                    author: article.author.name,
                    link: link,
                }).then(response=>{
                    if(response.status === 200){
                        setShowMailModal(false);
                        setInvitationSent(true);
                    } else {
                        setMailError(true);
                    }
                }).catch(err=>{
                    setMailError(true);
                })
            }
        })
    }
    function showInvitationSent() {
        return(
            <div>
                Votre invitation à lire cet article a bien été envoyé
            </div>
        )
    }
    function showMailSent() {
        return(
            <div>
                Votre email a bien été envoyé
            </div>
        )
    }
    function showMailError() {
        return(
            <div>
                Une erreur est survenu lors de l'envoi du mail, veuillez rééssayer plus tard.
            </div>
        )
    }
    function disableQuestion(id) {
        let disable = false;
        questionsAnswers.forEach(qa=>{
            if(qa.id === id && !qa.canModify){
                disable = true;
            }
        })
        return disable;
    }
    function isChecked(id, value) {
        let checked = false;
        questionsAnswers.forEach(qa=>{
            if(qa.id === id && qa.answer === value){
                checked = true;
            }
        })
        return checked;
    }
    return(
         Object.keys(article).length > 0 &&
                <div id={"article"} className={"articleContainer"}>
                    {mailSent &&
                    <Preview isOpen={mailSent} handleClose={()=>setMailSent(false)} component={showMailSent()}
                             showClose={false} boxWrapperClass={"mailSentModal"}/>
                    }
                    {invitationSent &&
                    <Preview isOpen={invitationSent} handleClose={()=>setInvitationSent(false)} component={showInvitationSent()}
                             showClose={false} boxWrapperClass={"mailSentModal"}/>
                    }
                    {mailError &&
                    <Preview isOpen={mailError} handleClose={()=>setMailError(false)} component={showMailError()}
                             showClose={false} boxWrapperClass={"mailErrorModal"}/>
                    }
                    <div className={"articleBanner"}>
                        <img
                            src={process.env.REACT_APP_API_URL + "images/" + article.coverImage.id + "/" + article.coverImage.name}
                            className={"articleBannerImg"} alt="Banner"/>
                        <button onClick={()=>props.history.goBack()} className={"articleBannerButton"}>
                            <img src={ReturnPicto} alt=""/>
                        </button>
                    </div>
                    <div className={"articleBody"}>
                        {articleLoaded ?
                        <div ref={componentRef} className={"articleContentWrapper"}>
                            <div className={"articleCategories"}>
                                {article.categories.map(category=>{
                                    return(
                                        <div key={category.id} className={`articleCategory category-${category.id}-border category-${category.id}-full`}>
                                            {category.name}
                                        </div>
                                    )
                                })}
                                {article.subCategories.map(subcategory=>{
                                    return(
                                        <div key={subcategory.id} className={`articleCategory articleSubcategory`}>
                                            {subcategory.name}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={"articleTitleBar"}>
                                <div className={`articleTitle category-${article.categories[0].id}-color`}>
                                    {article.title}
                                </div>
                                <div className={"articleButtons"}>

                                    <ReactToPrint
                                        trigger={() =>
                                            <button title={"Impression"} type={"button"} className={"articleButton articlePrintButton"}>
                                                <img src={PrintPicto} alt="Imprimer"/>
                                            </button>}
                                        content={() => componentRef.current}
                                    />
                                    {user.role !== 99 &&
                                        <div className={"shareButton"}>
                                            <button title={"Partage"} ref={shareButtonRef} type={"button"} onClick={() => setShowMailModal(!showMailModal)} className={"articleButton"}>
                                                <img src={SharePicto} alt="Partager"/>
                                            </button>
                                            {showMailModal &&
                                            <div ref={shareModalRef} className={"shareArticle"}>
                                                <div className={"shareArticleText"}>
                                                    Entrez l'adresse email afin de partager l'article
                                                </div>
                                                <input className={"shareArticleMail"} type="email" value={mail}
                                                       onChange={(e) => setMail(e.target.value)}/>
                                                <button onClick={() => sendShareLink()}
                                                        className={"shareArticleButton"}>
                                                    Partager
                                                </button>
                                            </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"articleDate"}>
                                <span style={{fontWeight:"bold"}}>{new Date(article.creationDate).toLocaleDateString('fr-FR',{ year: 'numeric', month: 'long', day: 'numeric' })}</span>
                            </div>
                            <div className={"articleText"} dangerouslySetInnerHTML={{__html: article.shortText}}/>
                            <div className={"articleText"} dangerouslySetInnerHTML={{__html: article.text}}/>
                            <div className={"articleAuthor"}>
                                Pour plus de renseignement, vous pouvez contacter <a className={"articleAuthorLink"} href={`mailto:${article.author.mail}`}>{article.author.name}</a>{article.author.phone ? ` au ${article.author.phone}` : ''}.
                            </div>
                            {article.files.length > 0 &&
                                <div className={"articleFiles"}>
                                {article.files.map(file=>{
                                    return(
                                        <a href={process.env.REACT_APP_API_URL+`download/${file.id}/${file.name}`} key={file.id} className={"articleFile"}>
                                            {file.name.slice(0,15)}
                                            {file.name.length>15 && "..."}
                                            <img className={"articleFilePicto"} src={DownloadPicto} alt=""/>
                                        </a>
                                    )
                                })}
                                {article.files.length > 1 &&
                                <a href={process.env.REACT_APP_API_URL+`downloadAll/${article.id}/`} className={"articleFile articleFileAll"}>
                                    Tout télécharger
                                    <img className={"articleFilePicto"} src={DownloadPictoWhite} alt=""/>
                                </a>
                                }
                            </div>
                            }

                            {(article.questions.length > 0 && user.role !== 99)&&
                                <div className={"articleQuestions"}>
                                    {article.questions.map(q=>{
                                        return(
                                         <div key={q.id} className={"articleQuestion"}>
                                             <div className={"articleQuestionText"}>
                                                 {q.question}
                                             </div>
                                             <form onSubmit={(e)=>handleSubmitQuestion(e,q.id)} className={"articleQuestionForm"}>
                                                 <div className={"articleQuestionAnswers"}>
                                                     <label className={"articleQuestionAnswer"}>
                                                         <input onChange={(e)=>handleChangeQuestion(e , q.id)} checked={isChecked(q.id, "Oui")} disabled={disableQuestion(q.id)} className={"radioButton"} type="radio" value={"Oui"} name={`question-${q.id}`}/>
                                                         Oui
                                                     </label>
                                                     <label className={"articleQuestionAnswer"}>
                                                         <input onChange={(e)=>handleChangeQuestion(e , q.id)} checked={isChecked(q.id, "Non")} disabled={disableQuestion(q.id)} className={"radioButton"} type="radio" value={"Non"} name={`question-${q.id}`}/>
                                                         Non
                                                     </label>
                                                 </div>
                                                 { !disableQuestion(q.id) &&
                                                     <input className={"articleSubmit"} type="submit" value={"ENVOYER"}/>
                                                 }
                                             </form>
                                         </div>
                                        )})
                                    }
                                </div>
                            }
                            {/* <div className={"articleComments"}>
                                {user.role !== 99 &&
                                <form onSubmit={handleSubmitComment} className={"newComment"}>
                                    <textarea value={newComment} placeholder={"Ajouter un commentaire"}
                                              className={"newCommentText"} rows="10"
                                              onChange={(e) => setNewComment(e.target.value)}/>
                                    <input className={"articleSubmit newCommentSubmit"} type="submit"
                                           value={"ENVOYER"}/>
                                </form>
                                }
                                <div className={"comments"}>
                                    {comments.slice(0,5*pageComment).map(comment=>{
                                        const date = new Date(comment.date)
                                        return(
                                            <div className={"comment"} key={comment.id}>
                                                <div className={"name"}>
                                                    {comment.person.name}
                                                </div>
                                                <div className={"date"}>
                                                    Publié le {date.toLocaleDateString('fr-FR',
                                                    { year: 'numeric', month: 'long', day: 'numeric'})} à {date.getHours()}h{(date.getMinutes()<10?"0":"")+date.getMinutes()}
                                                </div>
                                                <div className={"text"}>
                                                    {comment.comment}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                { comments.length > 5 * pageComment &&
                                    <button onClick={() => setPageComment(pageComment + 1)}
                                            className={"showMoreComments"}>
                                        Afficher plus de commentaires
                                    </button>
                                }
                            </div>*/}
                            <div className={"articleMail"}>
                                <form onSubmit={handleSubmitMail} className={"newComment"}>
                                    <textarea value={newMail} placeholder={"Envoyer un mail"}
                                              className={"newCommentText"} rows="10"
                                              onChange={(e) => setNewMail(e.target.value)}/>
                                    <input className={"articleSubmit newCommentSubmit"} type="submit"
                                           value={"ENVOYER"}/>
                                </form>
                            </div>
                        </div>
                        :<Loading/>
                        }
                        <div className={"lastArticles"}>
                            <div className={"lastArticlesTitle"}>Derniers articles</div>
                            {lastArticlesLoaded ?
                            lastArticles.map(la=>{
                                return(
                                    <Link to={`/article/${slugify(la.title+" "+la.id,{lower:true, strict:true})}`} key={la.id} className={"lastArticleWrapper"}>
                                        <div className={"lastArticleBanner"}>
                                            <img className={"lastArticleImg"}
                                                 src={`${process.env.REACT_APP_API_URL}images/${la.thumbnail ? la.thumbnail.id : la.coverImage.id}/${la.thumbnail ? la.thumbnail.name : la.coverImage.name}`} alt=""/>
                                            <div className={"lastArticleCategories"}>
                                                {la.categories.map(cat=>{
                                                    return (
                                                        <div key={cat.id} className={`lastArticleCategory category-${cat.id}-full`}>
                                                            {cat.name}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className={"lastArticleContent"}>
                                            <div className={"lastArticleTitle"}>
                                                {la.title}
                                            </div>
                                            <div className={"lastArticleDate"}>
                                                Publié le {new Date(la.creationDate).toLocaleDateString('fr-FR',{ year: 'numeric', month: 'long', day: 'numeric' })}
                                            </div>
                                            <div className={"lastArticleText"} dangerouslySetInnerHTML={{__html: la.shortText}}/>
                                        </div>
                                        <button
                                              className={`lastArticleButton category-${la.categories[0].id}-button`}>
                                            Lire la suite
                                        </button>
                                    </Link>
                                )
                            })
                            :<Loading/>
                            }
                        </div>
                    </div>
                </div>
    )
}