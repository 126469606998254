import React, {useContext, useEffect, useState} from "react";
import API from "../../utils/ApiParams";
import "./Login.css";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import SHA256 from "crypto-js/sha256";
import HEX from "crypto-js/enc-hex";
import CryptoJS from "crypto-js";
import Logo from "../../images/logoLogin.svg";
import userLogo from "../../images/auth/login.svg"
import passwordLogo from "../../images/auth/password.svg"
import addLogo from "../../images/auth/addPeople.svg"
import rightChevron from "../../images/whiteArrowRight.svg"
import {Redirect, useLocation} from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import Preview from "../modals/Preview";
import ForgottenPassword from "../modals/ForgottenPassword";
import RegisterRequest from "../modals/RegisterRequest";

export default function Login(props) {
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [error,setError] = useState({});
    const [redirect,setRedirect] = useState(false);
    const [loading,setLoading] = useState(false);
    const [showForgottenPassword, setShowForgottenPassword] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const user = useContext(UserContext);
    const search = useLocation().search;
    const loginParam = new URLSearchParams(search).get('login');
    const keyverif = new URLSearchParams(search).get('keyverif');

    useEffect(()=>{
        if(props.disconnect){
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        if(props.match.params.slug && props.match.params.slug === "register"){
            setShowRegister(true);
        }

        if(loginParam && keyverif){
            loginProginov().then(response=>{
                if(response.data && (response.data.token && response.data.id)){
                    document.cookie = `token=${response.data.token};path=/`;
                    props.onLog(response.data.role,response.data.id);
                    setRedirect(true);
                }
                setLoading(false);
            }).catch(err=>{
                let tempError = {};
                tempError.log = err.response.data.error;
                setError(tempError);
                setLoading(false);
            })
        }
    },[])

    useEffect(()=>{
        if(Object.keys(user).length>0){
            setRedirect(true);
        }
    }, [user, document.cookie])

    useEffect(()=>{
        if(Object.keys(error).length>0 && username){
            let tempError = {...error};
            delete tempError.log;
            delete tempError.username;
            setError(tempError);
        }
    },[username])

    useEffect(()=>{
        if(Object.keys(error).length>0 && password){
            let tempError = {...error};
            delete tempError.log;
            delete tempError.password;
            setError(tempError);
        }
    },[password]);

    async function loginProginov() {
        const response = await API.post(`login_proginov?login=${loginParam}&keyverif=${keyverif}`,{bo:false});
        return response;
    }

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        async function login() {

            let key = SHA256(process.env.REACT_APP_ENCRYPTION_KEY);
            key = HEX.parse(key.toString().slice(0,64));
            let iv = SHA256(process.env.REACT_APP_IV);
            iv = HEX.parse(iv.toString().slice(0,32));
            let encodedPassword = AES.encrypt(password, key, {iv:iv, padding: CryptoJS.pad.ZeroPadding});
            encodedPassword = encodedPassword.ciphertext.toString(CryptoJS.enc.Base64);
            console.log(username);
            console.log(encodedPassword);
            const response = await API.post("login",{login:username,password:encodedPassword,bo:false});

            return response;
        }
        let tempError = {};
        if(!username)
            tempError.username = "Nom d'utilisateur vide";
        if(!username){
            setError(tempError);
            setLoading(false);
        }
        if(username){
            login().then(response=>{
                if(response.data && (response.data.token && response.data.id)){
                    document.cookie = `token=${response.data.token};path=/`;
                    props.onLog(response.data.role,response.data.id);
                }
                setLoading(false);
            }).catch(err=>{
                console.log(err);
                tempError.log = err.response.data.error;
                setError(tempError);
                setLoading(false);
            })
        }
    }
    function showMailSuccess() {
        return(
            <div>
                Votre demande a bien été envoyée, nos administrateurs vous recontacteront dans les plus brefs délais.
            </div>
        )
    }
    if (redirect){
        if(props.urlRedirect){
            return (<Redirect push to={props.urlRedirect}/>)
        }else{
            return (<Redirect push to={"/accueil"}/>)
        }

    }
    return(
        <div className={"login"}>
            {showForgottenPassword &&
                <Preview isOpen={showForgottenPassword} handleClose={()=>setShowForgottenPassword(false)}
                component={<ForgottenPassword handleSuccess={()=>{
                 setMailSent(true);
                 setShowForgottenPassword(false);
             }}/>}
                boxWrapperClass={"loginModals"}/>
            }
            {mailSent &&
                <Preview isOpen={mailSent} handleClose={()=>{
                setMailSent(false);
            }} boxWrapperClass={"forgottenPasswordMailSent"}
                     component={showMailSuccess()} showClose={false}/>
            }
            {showRegister &&
                <Preview isOpen={showRegister} handleClose={()=>setShowRegister(false)} boxWrapperClass={"loginModals"}
                 component={<RegisterRequest handleSuccess={()=>{
                     setMailSent(true);
                     setShowRegister(false);
                 }}/>}/>
            }
            <img className={"loginLogo"} src={Logo} alt="Logo distrinews"/>
            <form className={"loginForm"} onSubmit={handleSubmit}>
                <p style={{color:'white', fontSize:'20px'}}>Chers adhérents, bienvenue sur le nouveau site Distrinews. Veuillez utiliser vos identifiants extranet Distriboissons pour vous connecter.</p>
                <div className={"loginInputGroup"}>
                    <input className={"loginInput"} placeholder={"Utilisateur"}  type="text" value={username} onChange={(e)=>setUsername(e.target.value)} />
                    <img className={"loginPicto"} src={userLogo} alt=""/>
                </div>
                <div className={"loginInputGroup"}>
                    <input className={"loginInput"} placeholder={"Mot de passe"} type="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                    <img className={"loginPicto"} src={passwordLogo} alt=""/>
                </div>
                { Object.keys(error).length > 0 &&
                <div className={"loginError"}>
                    {Object.keys(error).map((key,i)=>{
                        return <div key={i}>{error[key]}</div>
                    })}
                </div>
                }
                {/*<button onClick={()=>setShowForgottenPassword(true)} type={"button"} className={"loginForgottenPassword"}>*/}
                {/*    Mot de passe oublié*/}
                {/*</button>*/}
                <div className={"loginSubmit"}>
                    <input disabled={loading} className={"loginSubmitButton"} type="submit" value="CONNEXION" />
                    <img className={"loginPicto"} src={rightChevron} alt=""/>
                </div>
                <p className={"forgottenPassword"}><a href={"https://distrilog.distriboissons.com/META-LOGIN/connexion.html"} target={"_blank"}>Mot de passe oublié ?</a></p>
            </form>
            <div className={"loginRegisterGroup"}>
                <button onClick={()=>setShowRegister(true)} type={"button"} className={"loginRegister"}>
                    Demande d'inscription
                </button>
                <img className={"loginPicto"} src={addLogo} alt=""/>
            </div>
        </div>
    )
}