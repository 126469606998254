import React, {useEffect, useState, useContext} from "react";
import "./RegisterRequest.css";
import API from "../../utils/ApiParams";
import UserContext from "../../contexts/UserContext";


export default function RegisterRequest({handleSuccess}) {
    const [emptyFields, setEmptyFields] = useState(false);
    const [warehouseName, setWarehouseName] = useState("");
    const [warehouseCity, setWarehouseCity] = useState("");
    const [honorific,setHonorific] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    const [clientFirstName, setClientFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [segments, setSegments] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState({});
    const [mailError, setMailError] = useState(false);
    const user = useContext(UserContext);



    useEffect(()=>{
        async function getSegments() {
            const response = await API.get("segments");
            return response.data
        }
        getSegments().then(data=>{
            setSegments(data);
        })
    },[])
    function handleChangeSegment(e){
        if(e.target.value){
            setSelectedSegment(segments.find(s => s.id === parseInt(e.target.value)));
        } else setSelectedSegment({})
    }
    function handleSubmit(e) {
        e.preventDefault();
        if((warehouseName && warehouseName.length > 0) && (warehouseCity && warehouseCity.length > 0)
        && (honorific && honorific.length > 0) && (clientFirstName && clientFirstName.length > 0) && (clientLastName && clientLastName.length > 0)
        && (Object.keys(selectedSegment).length>0) && (email && email.length > 0) && (phone && phone.length >0)){
            setEmptyFields(false);
            async function sendMail() {
                const response = await API.post("mail/register", {
                    userId: user && user.id,
                    warehouse: warehouseName,
                    city: warehouseCity,
                    honorific: honorific,
                    lastName: clientLastName,
                    firstName: clientFirstName,
                    segment: selectedSegment.name,
                    mail: email,
                    phone: phone,
                });
                return response;
            }
            sendMail().then(response => {
                if (response.status === 200) {
                    handleSuccess();
                } else {
                    setMailError(true);
                }
            }).catch(err => {
                setMailError(true);
            })
        }else{
            setEmptyFields(true);
        }
    }
    return(
        <form className={"registerForm"} onSubmit={handleSubmit}>
            <h2>Demande d'inscription : réservé aux entrepôts adhérents Distriboissons</h2>
            <label>Nom de votre entrepôt</label>
            <input className={"registerInput"} type="text" value={warehouseName}
                   onChange={(e) => setWarehouseName(e.target.value)}
                   placeholder={"Ex : Mon entrepôt"}/>

            <label>Ville</label>
            <input className={"registerInput"} type="text" value={warehouseCity}
                   onChange={(e) => setWarehouseCity(e.target.value)}
                   placeholder={"Ex : Reims"}/>

            <label>Civilité</label>
            <select className={`registerInput`}
                    value={honorific} onChange={(e)=>setHonorific(e.target.value)}>
                <option disabled value="">Choisir civilité</option>
                <option value="M">M</option>
                <option value="Mme">Mme</option>
                <option value="Autre">Autre</option>
            </select>

            <label>Nom</label>
            <input className={"registerInput"} type="text" value={clientLastName}
                   onChange={(e) => setClientLastName(e.target.value)}
                   placeholder={"Ex : Dupont"}/>

            <label>Prénom</label>
            <input className={"registerInput"} type="text" value={clientFirstName}
                   onChange={(e) => setClientFirstName(e.target.value)}
                   placeholder={"Ex : Jean"}/>

            <label>Fonction</label>
            <select className={`registerInput`}  defaultValue={""}
                    value={selectedSegment.id} onChange={handleChangeSegment}>
                <option disabled value="">Choisir une fonction</option>
                {segments.map(segment=>{
                    return(
                        <option key={segment.id} value={segment.id}>{segment.name}</option>
                    )
                })}
            </select>

            <label>Adresse email</label>
            <input className={"registerInput"} type="email" value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   placeholder={"Ex : adresse@email.fr"}/>

            <label>Numéro de téléphone</label>
            <input className={"registerInput"} type="tel" value={phone}
                   onChange={(e) => setPhone(e.target.value)}
                   placeholder={"Ex : 0601020304"}/>

            {emptyFields &&
                <p className={"error"}>Attention, tous les champs doivent être renseignés</p>
            }
            <input className={"registerSubmit"} type="submit" value={"ENVOYER LA DEMANDE"}/>
        </form>
    )
}