import './App.css';
import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {UserProvider} from "./contexts/UserContext";
import Login from "./components/pages/Login";
import PrivateRoute from "./utils/PrivateRoute";
import Homepage from "./components/pages/Homepage";
import ArticleCategoryList from "./components/pages/ArticleCategoryList";
import Header from "./components/Header";
import Footer from "./components/Footer";
import GetToken from "./utils/GetToken";
import jwt_decode from "jwt-decode";
import {getCategories} from "./utils/ApiRequests";
import Article from "./components/pages/Article";
import LegalNotices from "./components/pages/LegalNotices";
import API from "./utils/ApiParams";
import ReactGA from 'react-ga';
import RouteChangeTracker from "./components/RouteChangeTracker";

const TRACKING_ID = "UA-171783100-2";
ReactGA.initialize(TRACKING_ID);

function App() {
    const [user,setUser] = useState({});
    const [userLoaded, setUserLoaded] = useState(false);
    const [disconnected, setDisconnected] = useState(false);
    const [categories, setCategories] = useState([]);
    const [urlRedirect, setUrlRedirect] = useState('');
    useEffect(()=>{
        getUserFromToken().then(data=>{
            setUser(data);
            setUserLoaded(true);
        })
    }, [])
    useEffect(()=>{
        if(isLogged()){
            getCategories().then(data=>{
                setCategories(data);
            });
        }else{
            if(window.location.pathname.substr(0, 8)==='/article'){
                setUrlRedirect(window.location.pathname);
            }
        }
    },[user])

    async function getUserFromToken() {
        let tempUser = {};

        async function verifyToken(token) {
            const response = await API.post("verifyToken",{token:token});
            return response.data;
        }

        const token = GetToken();

        if(token !== null) {
            await verifyToken(token).then(data=>{
                if(data && data.connection){
                    const decodedToken = jwt_decode(token);

                    if(decodedToken.exp*1000 > Date.now()) {
                        tempUser =  {role: decodedToken.access, id: decodedToken.id};
                    }
                }
            }).catch(e=>{
                // console.log(e.response);
            });
        }
        if(Object.keys(tempUser).length === 0){
            const splittedUrl = window.location.href.split('/');
            const lastSegment = splittedUrl.pop() || splittedUrl.pop();

            await verifyToken(lastSegment).then(data=>{
                if(data && data.connection) {
                    try{
                    const decodedToken = jwt_decode(lastSegment);
                        if (Object.keys(decodedToken).length>0){
                            if(decodedToken.exp*1000 > Date.now()){
                                document.cookie = `token=${lastSegment};path=/`;
                                tempUser = {role:decodedToken.access, id:decodedToken.id};
                            }
                        }
                    }catch (e) {
                    }
                }
            }).catch(e=>{
                // console.log(e.response);
            });
        }
        return tempUser;
    }
    function isLogged() {
        let tokenExpiryDate = 0;
        const token = GetToken();
        if(token !== null)
            tokenExpiryDate = jwt_decode(token).exp;
        return Object.keys(user).length>0 && tokenExpiryDate*1000 > Date.now();
    }
    function disconnect() {
        setUser({});
        setDisconnected(true);
    }
    function log(role, id) {
        setDisconnected(false);
        setUser({role,id});
    }
    return (
      <div className={"mainContainer"}>
          <UserProvider value={user}>
              <Router>
                  <RouteChangeTracker disconnect={disconnect}/>
                  { isLogged() &&
                    <Header categories={categories} disconnect={disconnect}/>
                  }
                  <div className={"flexContainer"}>
                      {((categories.length > 0 || Object.keys(user).length === 0) && userLoaded)&&
                      <Switch>
                          <PrivateRoute path={"/accueil"} condition={isLogged()} >
                              <Homepage categories={categories}/>
                          </PrivateRoute>
                          <PrivateRoute path={"/articles"} condition={isLogged()}>
                              <ArticleCategoryList categories={categories}/>
                          </PrivateRoute>
                          <PrivateRoute path={"/article/:slug"} condition={isLogged()}>
                            <Article disconnect={disconnect}/>
                          </PrivateRoute>
                          <Route path={"/mentions-legales"} condition={isLogged()}>
                            <LegalNotices/>
                          </Route>

                          {!isLogged()
                              ? <Route path={"/authentification/:slug"} render={(props)=><Login urlRedirect={urlRedirect} disconnect={disconnected} onLog={log} {...props}/>}/>
                              : <Route path={"/authentification/:slug"} render={(props)=><Homepage categories={categories} {...props}/>}/>

                          }
                          {/*<Route path={"/authentification/:slug"} render={(props)=><Login urlRedirect={urlRedirect} disconnect={disconnected} onLog={log} {...props}/>}/>*/}
                          <Route path={"/authentification"} render={(props)=><Login urlRedirect={urlRedirect} disconnect={disconnected} onLog={log} {...props}/>}/>

                          <Route>
                              {!isLogged()
                                  ? <Redirect to={"/authentification"}/>
                                  : <Redirect to={"/accueil"}/>
                              }
                          </Route>
                      </Switch>
                      }
                  </div>
                  {isLogged() &&
                  <Footer/>
                  }
              </Router>
          </UserProvider>
      </div>
  );
}

export default App;
