import React from "react";
import "./Footer.css";
import {Link} from "react-router-dom";
import FooterLogo from "../images/footer/Distriboissons.svg";

export default function Footer() {
    return(
        <div className={"footer"}>
            <img src={FooterLogo} alt="Logo Distriboissons"/>
            <div className={"footerLinks"}>
                <Link className={"footerLink"} to={"/mentions-legales"}>
                    Mentions légales
                </Link>
            </div>
        </div>
    )
}