import React, {useEffect, useRef, useState} from "react";
import "./CustomSelect.css";
import {Link} from "react-router-dom";

export default function CustomSelect({defaultValue, selectedItem, items, handleChange, displayField, link=false,
                                         inputClass, inputRowClass, activeInputClass, afterClass, listClass, itemClass}) {
    const [open, setOpen] = useState(false);
    const CustomSelectRef = useRef(null);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(CustomSelectRef);
    function handleChangeItem(item) {
        handleChange(item);
    }
    function handleOpen(e) {
        e.stopPropagation();
        setOpen(!open);
    }
    return(
        <div ref={CustomSelectRef} onClick={handleOpen} className={`customSelectInput ${inputClass ? inputClass : ""} ${open ? `${activeInputClass?activeInputClass:""}` : ""}`}>
            <div className={`customSelectInputRow ${inputRowClass?inputRowClass:""}`}>
                {Object.keys(selectedItem).length>0 ? selectedItem[displayField] : defaultValue}
                <span className={`${afterClass?afterClass:""}`}/>
            </div>
            <div className={`customSelectList ${listClass ? listClass : ""} ${open ? `customSelectActive` : ""}`}>
                {(!link && defaultValue) &&
                <div className={`${itemClass ? itemClass : ""}`} onClick={() => handleChangeItem("")}>
                    {defaultValue}
                </div>
                }
                {(link && defaultValue) &&
                <Link to={{
                    pathname:"/articles",
                    state: {category: null}
                }} className={`link ${itemClass ? itemClass : ""}`} onClick={() => handleChangeItem("")}>
                    {defaultValue}
                </Link>
                }
                {!link && items.map((item,i)=>{
                    return(
                        item &&
                        <div className={`${itemClass ? itemClass : ""}`} key={i} onClick={() => handleChangeItem(item)}>
                            {item[displayField]}
                        </div>
                    )
                })}
                {link && items.map((item,i)=>{
                    return(
                        item &&
                        <Link to={{
                            pathname:"/articles",
                            state: {category: item}
                        }} className={`link ${itemClass ? itemClass : ""}`} key={i} onClick={() => handleChangeItem(item)}>
                            {item[displayField]}
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}