import React, {useState} from "react";
import "./ForgottenPassword.css";

export default function ForgottenPassword({handleSuccess}){
    const [email, setEmail] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        if (email && email.length>0){
                const url = 'https://api.sendinblue.com/v3/smtp/email';
                const options = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'api-key': `${process.env.REACT_APP_SENDINBLUE_KEY}`
                    },
                    body: JSON.stringify({
                        sender: {name: 'Distrinews', email: 'alice.martelle@distriboissons.com'},
                        subject: `Mot de passe oublié • ${email}`,
                        to: [{email: `contact@distriboissons.com`}],
                        replyTo: {email: `${email}`},
                        htmlContent: `<div>${email} a fait une demande de mot de passe oublié</div>`
                    })
                };

                fetch(url, options)
                    .then(res => res.json())
                    .then(json => {
                        handleSuccess();
                    })
                    .catch(err => console.error('error:' + err));
        }
    }

    return(
        <form className={"forgottenPasswordForm"} onSubmit={handleSubmit}>
            <h2>Demande de réinitialisation de mot de passe</h2>
            <input className={"forgottenPasswordMail"} type="email" value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   placeholder={"adresse@email.fr"}/>
            <input className={"forgottenPasswordSubmit"} type="submit" value={"ENVOYER LA DEMANDE"}/>
        </form>
    )
}