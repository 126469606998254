import axios from 'axios';
import GetToken from "./GetToken";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "accept": "application/json",
    }
});

instance.interceptors.request.use(
    async (config) => {
        if (config.baseURL === process.env.REACT_APP_API_URL
            && !config.url.startsWith("login")
            && !config.url.startsWith("mail/register")
            && !config.url.startsWith("segments")
            && !config.url.startsWith("verifyToken")
            && !config.url.startsWith("login_proginov")) {
            config.headers.Authorization = `Bearer ${GetToken()}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response
    },
    (err) => {
        if (err.message && (err.message === "Expired JWT Token" || err.message === "Invalid JWT Token")) {
            window.location = "/authentication"
        }
        return Promise.reject(err);
    }
)

export default instance;