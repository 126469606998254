import React, {useContext, useEffect, useState} from "react";
import Logo from "../images/logoHeader.svg"
import "./Header.css"
import Search from "../images/menuHeader/search.svg";
import Setting from "../images/menuHeader/tools.svg";
import Add from "../images/menuHeader/add.svg";
import Logout from "../images/menuHeader/logout.svg";
import {Link} from "react-router-dom";
import UserContext from "../contexts/UserContext";
import {DebounceInput} from 'react-debounce-input';
import API from "../utils/ApiParams";
import slugify from "slugify";
import CustomSelect from "./CustomSelect";
import Preview from "./modals/Preview";
import RegisterRequest from "./modals/RegisterRequest";
import {useHistory} from "react-router-dom";
export default function Header(props) {
    const [selectedCategory, setSelectedCategory] = useState({});
    const [search, setSearch] = useState("");
    const [articles,setArticles] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [showMailSent, setShowMailSent] = useState(false);
    const user = useContext(UserContext)
    let history = useHistory();

    useEffect(()=>{
        async function getArticles() {
            let url = `articles?isArchived=false&status.index=2&title=${search}&itemsPerPage=10`;
            const response = await API.get(url);
            return response.data;
        }

        if(search.length>0){
            getArticles().then(data=>setArticles(data));
        }else{
            setArticles([])
        }

        if(history.location.pathname.substr(0, 26)=='/authentification/register'){
            setShowRegister(true);
        }
    }, [search])

    useEffect(()=>{
        let unlisten = history.listen((location, action) =>{
            if(!location.state || !location.state.category){
                setSelectedCategory({});
            } else if(location.state.category){
                setSelectedCategory(location.state.category);
            }
        })

        return function () {
            unlisten();
        }
    },[])
    function showMailSuccess() {
        return(
            <div>
                Votre demande a bien été envoyée, nos administrateurs vous recontacteront dans les plus brefs délais.
            </div>
        )
    }
    return(
        <div className={"header"}>
            {showRegister &&
            <Preview isOpen={showRegister} handleClose={()=>setShowRegister(false)} boxWrapperClass={"registerModal"}
                     component={<RegisterRequest handleSuccess={()=>{
                         setShowMailSent(true);
                         setShowRegister(false);
                     }}/>}/>
            }
            {showMailSent &&
            <Preview isOpen={showMailSent} handleClose={()=>{
                setShowMailSent(false);
            }} boxWrapperClass={"forgottenPasswordMailSent"}
                     component={showMailSuccess()} showClose={false}/>
            }
            <div className={"headerFilter"}/>
            <Link className={"headerLogoLink"} to={"/accueil"}>
                <img className={"headerLogo"} src={Logo} alt="Logo distrinews"/>
            </Link>
            <div className={"headerInputGroup headerMobileNone"}>
                <CustomSelect handleChange={(item)=>setSelectedCategory(item)} defaultValue={"Catégories"}
                items={props.categories} link={true} displayField={"name"} inputClass={"headerSelect"} listClass={"headerSelectList"}
                afterClass={"headerSelectAfter"} itemClass={"headerSelectItem"} selectedItem={selectedCategory}/>
            </div>

            <div className={"headerInputGroup headerInputSearch"}>
                <DebounceInput className={"headerInput headerSearchInput"} type="text" value={search} placeholder={"Recherche"}
                        onChange={(e)=>setSearch(e.target.value)} debounceTimeout={500}/>
                <img className={"headerPicto headerInputPicto"} src={Search} alt="Loupe"/>
                {(search.length>0 && articles.length>0) &&
                    <div className={"searchResults"}>
                        {articles.map(art => {
                            return (
                            <Link className={"searchResult"} key={art.id} to={`/article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`}>
                                {art.title}
                            </Link>
                            )
                        })}
                    </div>
                }
            </div>
            <div className={"headerButtons"}>
                {(user.role === 1 || user.role === 2) &&
                <a title={"Accès Back Office"} target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BO_URL} className={"headerButton headerMobileNone"}>
                    <img className={"headerPicto"} src={Setting} alt="Options"/>
                </a>
                }
                {user.role !== 99 &&
                <button title={"Demande d'inscription"} onClick={()=>setShowRegister(true)} className={"headerButton"}>
                    <img className={"headerPicto"} src={Add} alt="Ajouter"/>
                </button>
                }
                <button title={"Déconnexion"} className={"headerButton"} onClick={props.disconnect}>
                    <img className={"headerPicto"} src={Logout} alt="Déconnecter"/>
                </button>
            </div>
        </div>
    )
}